import CasinoBase from './base';
import Response from '../../rest/response';

/**
 * Example country model allowing us to fetch country data from the Casino API.
 */

class Country extends CasinoBase {
  all() {
    this.response = this.request('get', 'getCountries', '');

    return this.response;
  }

  setErrorResponse() {
    const data = {
      status: 'SUCCESS',
      countryInfo:
        { name: 'United States', iso2Code: 'US', phonePrefix: '1' },
      currency:
        { name: 'US Dollar', currencySymbol: '$', currencyCode: 'USD' },
    };

    return new Response(data);
  }

  getCountryInfo() {
    return this.request('get', 'getCountryInfo').then((res) => {
      this.response = res;
      if (!this.response.success()) {
        return this.setErrorResponse();
      }
      return this.response;
    }).catch(() => {
      this.response = this.setErrorResponse();
      return this.response;
    });
  }
}
export default Country;
